import React from "react";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { initialMenuOptionOnAddEdit } from "modules/aftermarketMenuConstructor/constants";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { MenuOptions } from "./MenuOptions";
import { MenuOptionsCardTitle } from "./MenuOptionsCardTitle";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";

interface Props {
  isProductsLoading: boolean;
}

export const MenuOptionsCard = ({ isProductsLoading }: Props) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(menuConstructorSelectors.isLoading);
  const isSectionCollapsed = useSelector((state: any) => menuConstructorSelectors.isSectionCollapsed(state, "menu"));

  const { handleOpen: handleAddEditMenuOptionDialogOpen } = useModal(ModalsKeys.AftermarketAddEditMenuOptionDialog);
  const { hasWriteAccess } = useAftermarketPermissions();

  const handleAddMenuOptionClick = () => {
    dispatch(
      menuConstructorActions.setMenuOptionToAddEdit({
        currentMenuOption: initialMenuOptionOnAddEdit,
      })
    );
    handleAddEditMenuOptionDialogOpen();
  };

  const handleChangeSectionLayout = () => {
    dispatch(
      menuConstructorActions.updateSectionLayout({
        section: "menu",
        key: "isCollapsed",
        value: !isSectionCollapsed,
      })
    );
  };

  return (
    <CardContainer
      isLoading={isLoading}
      title={
        <MenuOptionsCardTitle isCollapsed={isSectionCollapsed} onChangeSectionLayout={handleChangeSectionLayout} />
      }
      actionBtn={
        hasWriteAccess && (
          <Button size="small" color="primary" variant="outlined" onClick={handleAddMenuOptionClick}>
            Add Menu Option
          </Button>
        )
      }>
      <MenuOptions isCalculating={false} isProductsLoading={isProductsLoading} />
    </CardContainer>
  );
};
