import React, { FC } from "react";
import { useSelector } from "react-redux";
import { deskingSelectors } from "../../../../../model";
import { BuilderViewType, MenuCardsProps } from "../../../../../types";
import { FinanceMenuOption, FinancePaymentOption } from "../../../../components";

interface Props {
  financeAmount: number;
  isSectionOpen: boolean;
  isCalculating: boolean;
  isMAALessThenFinanceAmount: boolean;
}

export const BuilderCards = ({ financeAmount, isSectionOpen, isCalculating, isMAALessThenFinanceAmount }: Props) => {
  const builder = useSelector(deskingSelectors.builder);
  const financePrising = useSelector(deskingSelectors.financePrising);
  const menuBuilderViewType = useSelector(deskingSelectors.menuBuilderViewType);
  const menuPricing = useSelector(deskingSelectors.menuPricing);

  const renderFinanceOptionByMode: Record<BuilderViewType, FC<MenuCardsProps>> = {
    [BuilderViewType.Finance]: FinancePaymentOption,
    [BuilderViewType.AftermarketMenu]: FinanceMenuOption,
  };

  const FinanceOption = renderFinanceOptionByMode[menuBuilderViewType];

  return (
    <FinanceOption
      builder={builder}
      menuPricing={menuPricing}
      financeAmount={financeAmount}
      isCalculating={isCalculating}
      isSectionOpen={isSectionOpen}
      financePricing={financePrising}
      isMAALessThenFinanceAmount={isMAALessThenFinanceAmount}
    />
  );
};
